/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ececec;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.rcp-dark {
  --rcp-background: #181818;
  --rcp-input-text: #f3f3f3;
  --rcp-input-border: rgba(255, 255, 255, 0.1);
  --rcp-input-label: #999999;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  color: #333;
  background-color: #f9f9f9;
}

.btn-primary,
.btn-primary:active,
.btn-primary:focus {
  border-radius: 3px !important;
  background-color: rgb(29, 75, 140);
  color: #fff;
  border: 0px none;
  font-size: 13px;
  padding: 6px 15px;
  outline: 0px !important;
  box-shadow: none !important;
  font-weight: 300;
}

.btn-primary:hover {
  background-color: rgb(13, 49, 99);
}

.btn-secondary,
.btn-secondary:active,
.btn-secondary:focus {
  border-radius: 3px !important;
  background-color: rgb(102, 102, 102);
  color: #fff;
  border: 0px none;
  font-size: 13px;
  padding: 6px 10px;
  outline: 0px !important;
  box-shadow: none !important;
  font-weight: 300;
}

.btn-secondary:hover {
  background-color: rgb(78, 78, 78);
}

.btn-danger,
.btn-danger:active,
.btn-danger:focus {
  border-radius: 3px !important;
  background-color: #e20c19;
  color: #fff;
  border: 0px none;
  font-size: 13px;
  padding: 6px 10px;
  outline: 0px !important;
  box-shadow: none !important;
  font-weight: 300;
}

.btn-danger:hover {
  background-color: #333;
}

.btn-success,
.btn-success:active,
.btn-success:focus {
  border-radius: 3px !important;
  background-color: rgb(0, 129, 0);
  color: #fff;
  border: 0px none;
  font-size: 13px;
  padding: 6px 10px;
  outline: 0px !important;
  box-shadow: none !important;
  font-weight: 300;
}

.btn-success:hover {
  background-color: rgb(0, 99, 0);
}

.spanClose:hover {
  background-color: #e20c19;
  color: #fff;
  cursor: pointer;
}

.btn-warning,
.btn-warning:active,
.btn-warning:focus {
  border-radius: 3px !important;
  background-color: #ffc107;
  color: #333;
  border: 0px none;
  font-size: 13px;
  padding: 6px 10px;
  outline: 0px !important;
  box-shadow: none !important;
  font-weight: 300;
}

.btn-warning:hover {
  background-color: #e4b116;
}

.btn-icon {
  padding: 7px 15px 7px 0px;
  width: auto !important;
}

.btn-icon svg {
  margin: 0px 4px 1px 10px;
  font-size: 16px;
}

.btn-back {
  float: right;
  width: 85px;
}

.loader {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #fff;
  z-index: 5;
}

.loader div {
  position: relative;
  left: 50%;
  top: 50px;
  margin-left: -35px;
  width: 50px;
  height: 50px;
  margin-top: -35px;
}

.loader p {
  position: relative;
  left: 50%;
  top: 70px;
  margin-left: -120px;
  width: 240px;
  height: 30px;
}

.sidebar-open-button,
.sidebar-open-button:active,
.sidebar-open-button:focus {
  font-size: 27px;
  padding: 2px 7px 5px 5px;
  position: fixed;
  z-index: 1;
  border-radius: 0px !important;
  border-bottom-right-radius: 3px !important;
}

.sidebar {
  max-width: 325px;
}

.sidebar .logo {
  max-width: 325px;
  padding: 15px 20px;
  border-bottom: 1px solid #ddd;
}

.sidebar .program-name {
  background-color: #1d4b8c;
  padding: 12px 5px;
}

.sidebar .program-name h3 {
  color: #fff;
  font-size: 14px;
  text-align: center;
  margin: 0px;
  font-weight: 600;
}

.sidebar .user {
  padding: 10px 20px;
  font-size: 13px;
  color: #666;
  border-bottom: 1px solid #ddd;
}

.sidebar .user .photo {
  max-width: 60px;
  height: 60px;
  border: 1px solid #ddd;
  float: left;
  margin-right: 8px;
}

.sidebar .user p {
  margin-top: 12px;
  margin-bottom: -2px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 175px;
}

.sidebar .list-group {
  border: 0px none;
  border-radius: 0px;
}

.sidebar .list-group .list-group-item,
.sidebar .list-group .list-group-item:active,
.sidebar .list-group .list-group-item:focus {
  border: 0px none;
  border-radius: 0px;
  border-bottom: 1px solid #ddd;
  padding: 0px;
  background-color: transparent;
  position: relative;
}

.sidebar .list-group .list-group-item:hover {
  color: #000;
}

.sidebar .list-group .list-group-item.active {
  background-color: #f7f7f7;
  color: #000;
}

.sidebar .list-group .list-group-item svg {
  position: absolute;
  top: 15px;
  right: 10px;
}

.sidebar .list-group .list-group-item .list-group {
  margin-top: 0px !important;
  border-top: 1px solid #ddd;
}

.sidebar .list-group .list-group-item .list-group .list-group-item svg {
  position: relative;
  top: 0px;
  left: 10px;
  margin-right: 15px;
}

.sidebar .list-group .list-group-item .list-group {
  margin-top: 10px;
  background-color: #ececec;
}

.sidebar .list-group .list-group-item .list-group .list-group-item {
  padding: 0px;
}

.sidebar .list-group .list-group-item .list-group .list-group-item:last-child {
  border-bottom: 0px none;
  padding-bottom: 5px;
}

.sidebar .list-group .list-group-item .list-group .list-group-item.active {
  background-color: #f7f7f7;
  color: #000;
}

.sidebar .list-group .list-group-item .list-group .list-group-item svg {
  color: #fff;
  background-color: #1d4b8c;
  top: -1px;
  right: 3px;
  padding: 3px;
  font-size: 19px;
}

.sidebar .list-group .list-group-item a,
.sidebar .list-group .list-group-item a:active,
.sidebar .list-group .list-group-item a:focus {
  color: #333;
  display: block;
  padding: 10px 20px;
}

.sidebar .list-group .list-group-item a:hover {
  color: #000;
  text-decoration: none;
}

.sidebar .list-group .list-group-item .btn-link,
.sidebar .list-group .list-group-item .btn-link:active,
.sidebar .list-group .list-group-item .btn-link:focus {
  color: #333;
  display: block;
  padding: 10px 20px;
  font-size: 13px;
  display: block;
  width: 100%;
  text-align: left;
  outline: 0px !important;
  box-shadow: none !important;
  text-decoration: none;
}

.sidebar .list-group .list-group-item .btn-link:hover {
  color: #000;
  text-decoration: none;
}

.sidebar .footer,
.content .footer {
  font-size: 13px;
  padding: 10px 20px;
  color: #666;
  border-bottom: 1px solid #ddd;
}

.sidebar .footer p,
.content .footer p {
  margin-bottom: 3px;
}

.sidebar .footer p a,
.sidebar .footer p a:active,
.sidebar .footer p a:focus,
.content .footer p a,
.content .footer p a:active,
.content .footer p a:focus {
  color: #666;
  transition-duration: 0.02s;
}

.sidebar .footer p a:hover,
.content .footer p a:hover {
  color: #e20c19;
  text-decoration: none;
}

.sidebar .footer .note {
  margin-top: 15px;
  font-weight: 600;
  color: red;
}

.content .footer .note {
  font-weight: 600;
  color: red;
}

.content {
  margin-left: 35px;
  position: relative;
  padding: 30px;
  padding-top: 15px;
  padding-left: 15px;
  z-index: 0;
}

.modal .content {
  margin-left: auto !important;
}

.content .row {
  margin: 0px;
}

.content h1,
.modal-title {
  font-size: 23px;
  font-weight: 600;
  color: #114677;
  margin-bottom: 0px;
}

.modal-title {
  margin-left: 15px;
}

.content h2 {
  font-size: 17px;
  font-weight: 600;
  color: #e20a17;
  margin-bottom: 0px;
}

.content .breadcrumb {
  background-color: transparent;
  border-radius: 0px;
  padding: 0px;
  padding-top: 5px;
  margin: 0px;
}

a,
a:active,
a:focus {
  color: #333;
}

a:hover {
  color: #1d4b8c;
  text-decoration: none;
}

.content .btn a,
.content .btn a:active,
.content .btn a:focus,
.content .btn a:hover {
  color: #fff;
  text-decoration: none;
  padding: 6px 10px;
  display: block;
}

.content .card {
  border-radius: 3px;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  min-height: 200px;
  border: 1px solid #ddd;
}

label.font-weight-bolder {
  font-weight: 500 !important;
}

.form-control,
.form-control:active,
.form-control:focus {
  border: 1px solid #ddd;
  border-radius: 3px;
  font-size: 13px;
  padding: 19px 10px 17px 10px;
  outline: 0px !important;
  box-shadow: none !important;
}

.form-control:focus {
  border: 1px solid #aaa;
}

.form-control-select,
.form-control-select:active,
.form-control-select:focus {
  padding: 10px 10px 8px 10px;
  min-height: 150px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f9f9f9;
}

.datepicker {
  border: 1px solid #ddd;
  min-height: 40px;
  font-size: 13px;
  padding: 5px 10px;
  width: 100%;
  outline: 0px !important;
  box-shadow: none !important;
  border-radius: 3px;
}

.datepicker:focus {
  border: 1px solid #aaa !important;
}

.react-datepicker-wrapper {
  display: block !important;
}

.content .react-select__control {
  border: 1px solid #ddd;
  border-radius: 3px;
  box-shadow: none;
}

.content .react-select__control--is-focused {
  border: 1px solid #aaa !important;
}

.content .react-select__control .css-g1d714-ValueContainer {
  padding: 0px 10px 0px 10px;
}

.content .react-select__menu {
  border-radius: 0px;
  border: 1px solid #ddd;
  display: block !important;
}

.content .react-select__option {
  background-color: #fff;
  color: #333;
}

.content .react-select__option:hover {
  background-color: #1d4b8c;
  color: #fff;
}

.content .react-select__multi-value__remove,
.content .react-select__multi-value__label {
  background-color: #1d4b8c;
  color: #fff;
  border-radius: 0px;
  cursor: pointer;
}

.content .react-select__multi-value__remove:hover {
  background-color: #e20c19;
  color: #fff;
}

.custom-control-label {
  margin-left: 3px;
}

.custom-control-label::before {
  top: -1px;
  left: -27px;
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
  outline: 0px !important;
  box-shadow: none !important;
}

.custom-control-label::after {
  top: -1px;
  left: -27px;
  width: 20px;
  height: 20px;
  outline: 0px !important;
  box-shadow: none !important;
}

.custom-control-label:focus {
  outline: 0px !important;
  box-shadow: none !important;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0px;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #1d4b8c;
  background-color: #1d4b8c;
}

.alert {
  border-radius: 0px;
  padding: 10px 15px;
  /* margin-left:15px !important;
    margin-right: 15px !important; */
}

.collectionH2 {
  font-size: 13px;
  background-color: #f9f9f9;
  color: #333;
  border: 1px solid #ddd !important;
  font-weight: 500 !important;
  float: right;
  margin-left: 10px;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 3px;
  padding: 5px 15px !important;
}

.login-page .alert,
.remindList .alert,
.bankList .alert,
.calendarList .alert,
.categoryList .alert,
.collectionList .alert,
.companyUserGroupList .alert,
.costList .alert,
.paymentCalendarList .alert,
.costTypeList .alert,
.customerList .alert,
.employeeList .alert,
.incomeList .alert,
.collectionCalendarList .alert,
.offerList .alert,
.paymentList .alert,
.productList .alert,
.productEntryList .alert,
.productOutList .alert,
.productReturnList .alert,
.supplierList .alert {
  margin-left: unset !important;
  margin-right: unset !important;
}

.calendarList .loader svg {
  margin-top: -700px;
}

.dropdown-menu {
  border-radius: 0;
  position: absolute;
}

.dropdown-toggle {
  margin-left: 0 !important;
}

.content .btn-group {
  display: block;
  text-align: right;
}

.content .btn-group .btn,
.modal .content .btn-group .btn {
  width: 95px;
  margin-left: 8px;
  float: none;
  /* width: 100%; */
}

.iDzGLV {
  padding: 9px 10px !important;
  margin-right: 7px;
}

.content .contentTop .btn-group .btn {
  width: 100px;
  padding: 0px;
}

.content .contentTop .btn-group .btn a,
.content .contentTop .btn-group .btn a:active,
.content .contentTop .btn-group .btn a:focus,
.content .contentTop .btn-group .btn a:hover {
  padding: 6px 10px;
  display: block;
  width: 100%;
}

.content .footer {
  border: 0px none;
  text-align: right;
  padding-bottom: 100px;
  padding-right: 0px;
}

.content .breadcrumb-item+.breadcrumb-item::before {
  content: ">";
}

/* .mounthFilter{
    display: flex;
}

.selectedMount{
    width: 45%;
    margin-left: 15px;
}

.selectedYear{
    width: 45%;
    margin-left: 35px;
}

.btnCalendar{
    width: 10%;
    margin-left: 35px;
} */

.data-table {
  margin-bottom: -15px;
  margin-top: 0px;
}

.data-table .loading-box {
  position: relative !important;
}

.data-table .loading-box .loading {
  position: relative !important;
  margin: 0px auto;
  padding-top: 15px;
  padding-bottom: 15px;
}

.data-table .data-table-extensions .filter-text,
.data-table .data-table-extensions .filter-text:active,
.data-table .data-table-extensions .filter-text:hover {
  width: 100%;
  margin-bottom: 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
  font-size: 13px;
  padding: 9px 10px 8px 10px;
  outline: 0px !important;
  box-shadow: none !important;
}

.data-table .rdt_Pagination select {
  outline: none;
  width: 35px;
  color: #333;
}

.data-table .rdt_Pagination select:focus {
  outline: none;
}

.data-table .data-table-extensions .filter-text:focus {
  border: 1px solid #aaa;
}

.data-table .rdt_TableCol {
  font-size: 14px !important;
  font-weight: bold !important;
  color: #333;
}

.data-table .rdt_TableCell,
.data-table .rdt_Pagination span,
.data-table .rdt_Pagination button svg {
  color: #333;
}

.data-table .rdt_Pagination span {
  margin: 0px 10px;
}

.data-table #pagination-next-page,
.data-table #pagination-first-page,
.data-table #pagination-previous-page,
.data-table #pagination-last-page {
  border-radius: 3px;
}

.data-table #pagination-next-page:hover,
.data-table #pagination-first-page:hover,
.data-table #pagination-previous-page:hover,
.data-table #pagination-last-page:hover {
  background-color: #ddd;
  border-radius: 3px;
}

.data-table .btn {
  margin-left: 10px;
}

.data-table .rdt_TableRow {
  border-bottom-width: 2px !important;
  padding: 8px 0px;
  border-bottom-color: #d7d7d7 !important;
}

.data-table .cLiMnj {
  line-height: 17px !important;
}

.data-table .rdt_TableRow:hover {
  background-color: #f7f7f7;
  border-bottom-color: #ddd;
  outline-color: #ddd;
}



.badge {
  font-size: 12px;
  border-radius: 3px;
  font-weight: 300;
  padding: 9px 0px;
  height: 31px;
  letter-spacing: 0.5px;
  opacity: 0.95;
}

.badge-success,
.badge-danger,
.badge-info {
  min-width: 85px;
}

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.75) !important;
}

.react-confirm-alert-overlay .react-confirm-alert .react-confirm-alert-body {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: normal;
  color: #333;
}

.react-confirm-alert-overlay .react-confirm-alert .react-confirm-alert-body {
  border-radius: 3px;
}

.react-confirm-alert-overlay .react-confirm-alert .react-confirm-alert-body button:first-child,
.react-confirm-alert-overlay .react-confirm-alert .react-confirm-alert-body button:first-child:active,
.react-confirm-alert-overlay .react-confirm-alert .react-confirm-alert-body button:first-child:focus {
  border-radius: 3px !important;
  background-color: rgb(29, 75, 140);
  color: #fff;
  border: 0px none;
  font-size: 13px;
  padding: 6px 10px;
  outline: 0px !important;
  box-shadow: none !important;
  width: 65px;
}

.react-confirm-alert-overlay .react-confirm-alert .react-confirm-alert-body button:first-child:hover {
  background-color: rgb(13, 49, 99);
}

.react-confirm-alert-overlay .react-confirm-alert .react-confirm-alert-body button:last-child,
.react-confirm-alert-overlay .react-confirm-alert .react-confirm-alert-body button:last-child:active,
.react-confirm-alert-overlay .react-confirm-alert .react-confirm-alert-body button:last-child:focus {
  border-radius: 3px !important;
  background-color: rgb(102, 102, 102);
  color: #fff;
  border: 0px none;
  font-size: 13px;
  padding: 6px 10px;
  outline: 0px !important;
  box-shadow: none !important;
  width: 65px;
}

.react-confirm-alert-overlay .react-confirm-alert .react-confirm-alert-body button:last-child:hover {
  background-color: rgb(78, 78, 78);
}

.react-confirm-alert-overlay .react-confirm-alert .react-confirm-alert-body>h1 {
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  font-weight: bold;
  color: #1d4b8c;
}

.table {
  margin: 0px;
  display: inline-table;
}

.table th {
  width: 235px;
}

.table tr:first-child th,
.table tr:first-child td {
  border-top: 0px none;
}

.table tr th,
.table tr td {
  border-top: 1px solid #ddd;
}

.table .colon {
  width: 25px;
  text-align: center;
}

.table-hover tbody tr:hover {
  background-color: #f7f7f7;
  color: #333;
  cursor: pointer;
}

.home-card {
  min-height: 150px !important;
}

a:hover .home-card {
  background-color: #1d4b8c;
}

a:hover .home-card div h1 {
  color: #fff;
}

.home-card div {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
  padding: 50px;
}

.home-card div h1 {
  font-size: 20px;
}

.home-card svg {
  color: #1d4b8c;
  font-size: 50px;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

a:hover .home-card svg {
  color: #fff;
}

.home-card .badge {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 40px;
  height: 40px;
  line-height: 33px;
  text-align: center;
  background-color: #1d4b8c;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  border-radius: 0px;
}

a:hover .home-card .badge {
  background-color: #fff;
  color: #1d4b8c;
}

.login-page {
  background-color: #585858;
  background-image: url("../images/bg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: relative;
}

.login-page .card {
  border: 0px none;
  border-radius: 0px;
  width: 500px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -250px;
  margin-top: -220px;
  background-color: transparent;
}

.login-page .card .card-header {
  background-color: #f1f1f1;
  border-bottom: 5px solid #dee2e6;
  border-radius: 0px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 20px 30px 20px 30px;
  padding-bottom: 15px;
}

.login-page .card .card-header h4 {
  font-size: 14px;
  font-weight: 400;
  color: #333;
  padding: 0;
  margin: 0;
  margin-top: 5px;
}

.login-page .card .card-header h1 {
  font-size: 20px;
  font-weight: 500;
  color: #333;
  padding: 0;
  margin: 0;
}

.login-page .card .card-header .logo {
  width: 100%;
  float: right;
}

.login-page .card .card-body {
  padding: 25px 30px 30px 30px;
  background-color: #fff;
  box-shadow: 0 3px 5px rgb(0 0 0 / 30%);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.login-page .card .card-footer {
  background-color: transparent;
  border: 0px none;
  text-align: center;
}

.login-page .card .card-footer p {
  color: #aeb6a9;
  margin: 0px;
  margin-top: 15px;
}

.login-page .card .card-footer p a,
.login-page .card .card-footer p a:active,
.login-page .card .card-footer p a:focus {
  color: #aeb6a9;
}

.login-page .card .card-footer p a:hover {
  text-decoration: none;
  color: #fff;
}

.login-page .recaptcha div {
  width: 100% !important;
}

.error-page {
  text-align: center;
  background-color: #585858;
  background-image: url("../images/bg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: relative;
  padding-top: 125px;
}

.error-page-content {
  text-align: center;
  height: 100vh;
  position: relative;
  padding-top: 125px;
}

.error-page h1,
.error-page-content h1 {
  font-size: 250px;
  line-height: 225px;
  font-weight: bold;
  color: #fff;
}

.error-page-content h1 {
  color: #333;
}

.error-page h3,
.error-page-content h3 {
  font-size: 48px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 30px;
}

.error-page-content h3 {
  color: #333;
}

input[type="file"] {
  color: transparent !important;
}

.ListDownButton {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 3px;
  color: #333;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  padding: 4px 9px;
  margin-right: 0px !important;
  margin-left: 10px;
  margin-bottom: 10px;
}

.dynamicRowAddButton {
  height: 35px;
}

.dynamicRowDeleteButton {
  width: 100%;
  margin-top: 27px;
  height: 40px;
}

.dynamicRowDeleteButton2 {
  width: 75px;
  margin-top: 25px;
  height: 33px;
  padding: 7px;
  float: right;
  display: block;
}

.rbc-overlay-header {
  display: none;
}

.rbc-toolbar .rbc-btn-group {
  display: none !important;
}

.rbc-toolbar .rbc-btn-group button:first-child {
  float: right !important;
}

.rbc-toolbar .rbc-btn-group button {
  background-color: #1d4b8c;
  color: #fff !important;
  margin-right: 5px !important;
}

.crowdCell {
  font-weight: bold;
  color: #1d4b8c;
}

.fVMMIP div:first-child {
  white-space: unset !important;
}

.nav-tabs {
  background-color: #ddd;
}

.nav-tabs .nav-item {
  cursor: pointer;
  margin-right: 2px;
}

.nav-tabs .nav-link {
  border: none;
  font-weight: 600;
  font-size: 14px;
  color: #666;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #1d4b8c;
  color: #fff;
  border-radius: 0;
}

.tab-content {
  margin: 0 5px;
}

.customerDetail .customerDetailCard,
.supplierDetail .supplierDetailCard {
  padding: 20px 0 20px 0;
  margin-top: 0;
}

.personelBtn {
  padding: 8px 2px !important;
  margin-left: 0px !important;
}

.modal.fade .modal-xs {
  max-width: 40% !important;
}

.offerListSendMailAlert {
  width: 80px !important;
}

/* .offerCoverList .data-table .rdt_image{
    width: 100px !important;
    height: 100px !important;
} */
.noteCard {
  min-height: unset !important;
}

.noteAuthor {
  font-size: 16px;
  color: #1d4b8c;
  font-weight: bold;
}

.noteList .noteCard {
  background-color: #f7f7f7;
  padding-left: 35px;
}

.noteList .noteCard:not(:last-child) {
  margin-bottom: 25px;
}

.noteList .noteListButton {
  min-width: 100px;
}

.noteList .noteTitle {
  font-size: 14px;
  margin-bottom: 5px;
}

.noteList .buttonGroupCol {
  margin: auto 0;
}

.noteList .buttonGroupCol .noteListStatus {
  margin-right: 30px;
}

.barcodeButton,
.barcodeButton:active,
.barcodeButton:focus {
  margin-top: 26px;
  padding: 10px 0 10px 0;
  float: right;
  margin-left: 10px;
}

/* .productEntryAddUpdate .productAdd, .productOutAddUpdate .productAdd{
    margin-left: 15px;
} */
.modalButton {
  width: 85px;
  margin-right: 20px;
}

.close span {
  margin-right: 15px;
}

.modalTextArea {
  height: 200px !important;
  padding: 10px !important;
}

.modalAddItemButton {
  position: absolute;
  right: -21px;
  top: 27px;
  font-size: 20px;
  border: 1px solid #ddd;
  padding: 3px 10px;
  border-left: none;
}

.calendarBoxColorDiv {
  margin-left: 15px;
}

.calendarBoxColorDiv .calendarBoxColor {
  margin-right: 8px;
  font-weight: 500;
}

.calendarBoxColorDiv .calendarBoxColor span {
  display: inline-block;
  margin-right: 2px;
}

.calendarBoxColorDiv .costBoxColor {
  border: 5px solid #a93226;
}

.calendarBoxColorDiv .paymentBoxColor {
  border: 5px solid #e74c3c;
}

.calendarBoxColorDiv .paymentCalendarBoxColor {
  border: 5px solid #f1948a;
}

.calendarBoxColorDiv .incomeBoxColor {
  border: 5px solid #196f3d;
}

.calendarBoxColorDiv .collectionBoxColor {
  border: 5px solid #27ae60;
}

.calendarBoxColorDiv .collectionCalendarBoxColor {
  border: 5px solid #7dcea0;
}

.calendarBoxColorDiv .remindBoxColor {
  border: 5px solid #1d4b8c;
}

.calendarBoxColorDiv .offerBoxColor {
  border: 5px solid #d4ac0d;
}

.calendarBoxColorDiv .serviceBoxColor {
  border: 5px solid #2196f3;
}

.calendarBoxColorDiv .birthdayBoxColor {
  border: 5px solid #d2b4de;
}

.calendarBoxColorDiv .assignmentBoxColor {
  border: 5px solid #9533ff;
}

.calendarBoxColorDiv .machineServiceBoxColor {
  border: 5px solid #3337ff;
}

.rbc-calendar {
  height: 3000px !important;
}

/* .rbc-ellipsis, .rbc-event-label, .rbc-row-segment .rbc-event-content, .rbc-show-more{
    display: block;
     overflow: unset !important;
    text-overflow: unset !important;
    white-space: unset !important;
} */

.dropDownBtn:active,
.dropDownBtn:focus {
  background-color: #e9ecef;
  color: #333;
}

.dropDownBtn,
.dropDownBtn:hover,
.dropDownBtn:active,
.dropDownBtn:focus {
  font-size: 14px;
  text-align: left !important;
}

/* #column-2 {
  margin-left: 12px;
} */
.employeeList #column-2 {
  margin-left: unset !important;
}

.employeeList #column-3 {
  margin-left: 12px;
}

.employeeList #cell-3-undefined {
  margin-right: -5px !important;
}

.employeeList #cell-4-undefined {
  margin-right: 3px !important;
}

.offerList .badge {
  min-width: 100% !important;
}

.rbc-event {
  border-radius: 0;
}

/* .data-table .rdt_TableRow {
    border-bottom-color: #fff;
    border-bottom-width: 5px!important;
} */
.data-table .rdt_image {
  width: 60px;
  height: 60px;
  border-radius: 0 !important;
  border: 1px solid #ddd;
  margin-top: 5px;
  margin-bottom: 5px;
}

#list .table th {
  font-weight: 500 !important;
}

#list .table td,
#list .table th {
  padding: 2px;
  font-size: 13px !important;
}

.employeeList .table td .table th {
  font-weight: 400 !important;
}

/* #list .table tr th, .table tr td, .table td, .table th{
border-top: none;
} */
#list .table tr th:first-child {
  padding-left: 0;
  color: #000;
  font-weight: bold;
}

.remindList .table tr th:first-child {
  width: 120px;
}

.categoryList .table tr th:first-child {
  width: 115px;
}

.costList .table tr th:first-child {
  width: 105px;
}

.paymentCalendarList .table tr th:first-child,
.collectionCalendarList .table tr th:first-child {
  width: 170px;
}

.incomeList .table tr th:first-child,
.offerList .table tr th:first-child,
.productEntryList .table tr th:first-child,
.productOutList .table tr th:first-child {
  width: 140px;
}

.paymentList .table tr th:first-child {
  width: 150px;
}

/* .productList .table tr th{
    width: 50px;
} */
.costTypeList #cell-1-undefined div {
  width: 200px;
}

.bankMovementList .card {
  position: relative;
}

.bankMovementList .btn-back {
  position: absolute;
  right: 15px;
  bottom: -45px;
  z-index: 1;
}

.bankMovementList+div .footer {
  margin-top: 50px;
}

.modalImage {
  width: 100% !important;
  height: 100% !important;
}

/* .modalButton{
    background-color: unset !important;
} */
.offerCoverButton,
.offerCoverButton:active,
.offerCoverButton:focus,
.offerCoverButton:hover {
  padding: 0px;
  width: auto;
  margin-right: 10px;
}

.offerCoverImage {
  height: 100px;
  width: 100px;
  border: 3px solid #ddd;
}

.offerCoverImageSelected {
  border-color: #195595;
}

.reportCircle {
  position: relative;
  width: 100%;
  height: 160px;
  display: inline-block;
  border: 1px solid #ddd;
  padding: 20px;
  position: relative;
  z-index: 0;
  margin-bottom: 30px;
  background-color: #ffffff;
}

.reportCircle2 {
  position: relative;
  width: 100%;
  height: 150px;
  display: inline-block;
  padding: 20px;
  position: relative;
  z-index: 0;
  margin-bottom: 30px;
  /* background-color: #ffffff; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background-image: linear-gradient(to left, #48d6a8 0%, #26aa80 100%);
  border-radius: 3px;
}

.reportCircle3 {
  position: relative;
  width: 100%;
  height: 150px;
  display: inline-block;
  padding: 20px;
  position: relative;
  z-index: 0;
  margin-bottom: 30px;
  /* background-color: #ffffff; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background-image: linear-gradient(to left, #efa65f, #f76a2d) !important;
  border-radius: 3px;
}

.reportCircle4 {
  position: relative;
  width: 100%;
  height: 150px;
  display: inline-block;
  padding: 20px;
  position: relative;
  z-index: 0;
  margin-bottom: 30px;
  /* background-color: #ffffff; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background-image: linear-gradient(to left, #0db2de 0%, #005bea 100%) !important;
  border-radius: 3px;
}

.reportCircle5 {
  position: relative;
  width: 100%;
  height: 150px;
  display: inline-block;
  padding: 20px;
  position: relative;
  z-index: 0;
  margin-bottom: 30px;
  /* background-color: #ffffff; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background-image: linear-gradient(45deg, #f93a5a, #f7778c) !important;
  border-radius: 3px;
}

.reportCircle6 {
  position: relative;
  width: 100%;
  height: 150px;
  display: inline-block;
  padding: 20px;
  position: relative;
  z-index: 0;
  margin-bottom: 30px;
  /* background-color: #ffffff; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background-image: linear-gradient(45deg, #3791c5, #998adf) !important;
  border-radius: 3px;
}

.reportCircle7 {
  position: relative;
  width: 100%;
  height: 150px;
  display: inline-block;
  padding: 20px;
  position: relative;
  z-index: 0;
  margin-bottom: 30px;
  /* background-color: #ffffff; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background-image: linear-gradient(45deg, #eed960, #e4cf17) !important;
  border-radius: 3px;
}

.reportTitle {
  letter-spacing: 0.5px;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
  text-align: left;
  color: #e31d29;
  position: relative;
  z-index: 1;
}

.reportTitleGreen {
  color: #196f3d;
}

.reportTitleGreen2 {
  color: #fff;
  font-size: 23px;
  position: relative;
  top: 20px;
}


.reportTitleGreen3 {
  color: #333;
  margin: 0 auto;
  text-align: center;
}

.reportTitleOrange {
  color: #195595;
}

.reportTitle span {
  font-size: 15px;
}

.reportTotal {
  font-weight: 700;
  text-align: right;
  font-size: 27px;
  position: relative;
  z-index: 1;
}

.reportTotal2 {
  text-align: right;
  font-size: 18px;
  position: relative;
  z-index: 1;
  color: #fff;
}

.reportTotal3 {
  font-size: 20px;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
}

.pieChart {
  width: 80%;
}

.borderRow {
  border: 1px solid #d8d8d8;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.reportTotal span {
  font-size: 20px;
}

.grafik01 {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}

.grafik04 {
  width: 100%;
  position: relative;
  bottom: -1px;
  float: right;
}

.firstCircle {
  color: #59BAD2;
  font-size: 14px;
}

.firstCircle2 {
  color: #0B7F9B;
  font-size: 14px;
}

.firstCircle3 {
  color: #114677;
  font-size: 14px;
}

.firstCircle4 {
  color: #EB9183;
  font-size: 14px;
}

.firstCircle5 {
  color: #EA120C;
  font-size: 14px;
}

.firstCircle6 {
  color: #83EBAA;
  font-size: 14px;
}

.firstCircle7 {
  color: #298E4F;
  font-size: 14px;
}

.firstCircle8 {
  color: #5B4E9D;
  font-size: 14px;
}

.firstCircle9 {
  color: #7A69CE;
  font-size: 14px;
}

.firstCircle10 {
  color: #ACA2DE;
  font-size: 14px;
}

.report hr {
  margin-top: -10px;
  border-top: 5px solid #ddd;
}

.image-upload img {
  width: 123px !important;
  height: 123px !important;
  padding: 10px !important;
  box-shadow: none !important;
  margin-bottom: 0px !important;
  border-radius: 0px !important;
  border: 1px solid #ddd;
  margin-left: 1px;
}

.image-upload .btn-danger {
  display: block;
  width: 123.4px;
  margin-top: -1px;
  margin-left: 1px;
  z-index: 1;
  position: relative;
}

.list-group-item+.list-group-item.active {
  margin-top: 0px;
}

/* .image-upload label {
  margin-top: 10px;
} */

.image-upload .form-control-file {
  width: 165px;
  height: 40px;
  display: block;
  outline: none !important;
  box-shadow: none !important;
}

.image-upload .form-control-file::before {
  content: "Fotoğraf/Dosya Seç";
  width: 100%;
  display: inline-block;
  text-align: center;
  position: relative;
  cursor: pointer;
  border-radius: 3px !important;
  background-color: rgb(102, 102, 102);
  color: #fff;
  border: 0px none;
  font-size: 13px;
  padding: 10px 15px;
  outline: 0px !important;
  box-shadow: none !important;
}

.rdt_TableCell {
  padding: 0px 15px !important;
}

.table-product {
  margin-top: 20px;
  border: 3px solid #1d4b8c;
}

.table-product th,
.table-product td {
  text-align: right;
  padding: 12px 10px;
}

.table-product-2 th,
.table-product-2 td {
  text-align: left;
}

.table-product th:first-child,
.table-product td:first-child {
  text-align: left;
}

.table-product tr {
  background-color: transparent;
}

.bottomRow {
  background-color: #1d4b8c !important;
  color: #fff;
}

.table-product tr:first-child {
  background-color: #1d4b8c !important;
}

.table-product tr:first-child th {
  padding: 8px 10px;
}

.table-product2 {
  margin-top: 10px;
  border: 1px solid #ddd;
}

.table-product2 th,
.table-product2 td {
  text-align: right;
  padding: 12px 10px;
}

.table-product2-2 th,
.table-product2-2 td {
  text-align: left;
}

.table-product2 th:first-child,
.table-product2 td:first-child {
  text-align: left;
  padding-left: 5px;
}

.table-product2 th:last-child,
.table-product2 td:last-child {
  padding-right: 5px;
}

.table-product2 tr {
  background-color: transparent !important;
}

.table-product2 tr:first-child {
  background-color: #9B9796 !important;
}

.table-product2 tr:first-child th {
  background-color: #f9f9f9 !important;
  padding: 10px 5px;
  color: #333 !important;
}

.table-product3 {
  margin-top: 10px;
  border: 3px solid #9B9796;
}

.table-product3 th,
.table-product3 td {
  text-align: left;
  padding: 12px 10px;
}

.table-product3-2 th,
.table-product3-2 td {
  text-align: left;
}

.table-product3 th:first-child,
.table-product3 td:first-child {
  text-align: left;
  padding-left: 12px;
}

.table-product3 th:last-child,
.table-product23 td:last-child {
  padding-right: 12px;
}

.table-product3 tr {
  background-color: transparent !important;
}

.table-product3 tr:first-child {
  background-color: #9B9796 !important;
}

.table-product3 tr:first-child th {
  padding: 8px 10px;
}

.table-productCount {
  margin-top: 20px;
  border: 3px solid #1d4b8c;
}

.table-productCount th,
.table-productCount td {
  text-align: right;
  padding: 12px 10px;
}

.table-productCount-2 th,
.table-productCount-2 td {
  text-align: left;
}

.table-productCount th:first-child,
.table-productCount td:first-child {
  text-align: left;
}

.table-productCount tr:first-child {
  background-color: #1d4b8c !important;
}

.table-productCount tr:first-child th {
  padding: 8px 10px;
}

.table-spare {
  margin-top: 20px;
  border: 3px solid #1d4b8c;
}

.table-spare th,
.table-spare td {
  text-align: left;
  padding: 12px 10px;
}

.table-spare th:first-child,
.table-spare td:first-child {
  text-align: left;
}

.table-spare tr {
  background-color: transparent !important;
}

.table-spare tr:first-child {
  background-color: #1d4b8c !important;
}

.table-spare tr:first-child th {
  padding: 8px 10px;
}

.rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
  overflow: initial;
  text-overflow: initial;
  white-space: initial;
}

.content .card-body .row .col-lg-1:first-child,
.content .card-body .row .col-lg-2:first-child,
.content .card-body .row .col-lg-3:first-child,
.content .card-body .row .col-lg-4:first-child,
.content .card-body .row .col-lg-6:first-child,
.content .card-body .row .col-lg-8:first-child,
.content .card-body .row .col-lg-9:first-child,
.content .card-body .row .col-lg-12:first-child,
.content .card-body .row .col-3,
.content .card-body .row .col-9 {
  padding-left: 0px;
}

.content .card-body .row .col-lg-1:last-child,
.content .card-body .row .col-lg-2:last-child,
.content .card-body .row .col-lg-3:last-child,
.content .card-body .row .col-lg-4:last-child,
.content .card-body .row .col-lg-6:last-child,
.content .card-body .row .col-lg-8:last-child,
.content .card-body .row .col-lg-9:last-child,
.content .card-body .row .col-lg-12:last-child,
.content .card-body .row .col-3,
.content .card-body .row .col-9 {
  padding-right: 0px;
}

.rdt_TableCell,
.rdt_TableCol {
  padding-left: 0px !important;
}

.rdt_TableHeadRow {
  min-height: 38px !important;
}

.table th {
  font-weight: 400 !important;
}

.rdt_Table {
  min-height: 250px;
}

.cuttingTr {
  background-color: #1d4b8c;
  color: #fff;
}

.cuttingTr:hover {
  background-color: #1d4b8c !important;
  color: #fff !important;
}

.cuttingTr2 {
  background-color: #29b237;
  color: #fff;
}

.cuttingTr2:hover {
  background-color: #29b237 !important;
  color: #fff !important;
}

.cuttingTr3 {
  background-color: red;
  color: #fff;
}

.cuttingTr3:hover {
  background-color: red !important;
  color: #fff !important;
}

.cuttingTr4 {
  background-color: #9B9796;
  color: #fff;
}

.cuttingTr4:hover {
  background-color: #9B9796 !important;
  color: #fff !important;
}

.modal-header,
.modal-content {
  border-radius: 0px !important;
}

.modalButtonDetail,
.modalButtonDetail:active,
.modalButtonDetail:focus {
  padding: 0px;
  width: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.modalButtonList,
.modalButtonList:active,
.modalButtonList:focus {
  padding: 0px;
  width: auto;
  margin: 0px !important;
  margin-left: 10px !important;
}

.rdt_no_image {
  margin-left: 10px;
}

.cart_image_button {
  width: 69px;
  height: 69px;
  margin: 0px !important;
  padding: 0px !important;
  float: left;
  margin-right: 30px !important;
}

.cart_no_image {
  width: 69px;
  height: 69px;
  border-radius: 0 !important;
  border: 1px solid #ddd;
  float: left;
  margin-right: 30px;
}

.modalButtonList img {
  margin: 0px !important;
}

.modalSelectAddButton,
.modalSelectAddButton:hover,
.modalSelectAddButton:active,
.modalSelectAddButton:focus {
  max-width: 46px;
  font-size: 25px;
  margin-top: 27px;
  height: 38px;
  line-height: 0.5;
}

.table th,
.table td {
  padding: 8px 5px;
}

.btnReport {
  margin-top: 26px !important;
  height: 41px !important;
  background-color: rgb(29, 75, 140);
}

.teklif-box {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: #585858;
  background-image: url("../images/bg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
}

.teklif {
  background-color: #f1f1f1;
  text-align: center;
  width: 600px;
  position: absolute;
  top: 50%;
  margin-top: -100px;
  left: 50%;
  margin-left: -300px;
  z-index: 1;
  padding: 30px;
}

.teklif h3 {
  font-size: 30px;
  font-weight: 700;
}

.teklif p {
  margin-bottom: 5px;
}

.teklif-onay h3 {
  color: #196f3d;
}

.teklif-iptal h3 {
  color: #a93226;
}

.teklif-box .alert {
  text-align: center;
  width: 600px;
  position: relative;
  top: 50%;
  margin-top: -50px;
  left: 50%;
  margin-left: -300px;
  z-index: 1;
}

.modalofferCoverImage {
  width: 100% !important;
  height: 100% !important;
}

.productFilterButton {
  margin-bottom: 15px !important;
}

.productFilterButton .btn-group {
  float: left !important;
}

.productFilterButton .btn-group .btn {
  width: auto !important;
  margin-left: 0px !important;
  margin-right: 8px !important;
  float: left !important;
  margin-bottom: 8px !important;
}

.customerDetail .customerDetailCard .card-body {
  padding: 0px !important;
  border: 0px none !important;
}

.customerDetail .customerDetailCard {
  border: 1px solid #ddd !important;
}

.customerDetail .customerDetailCard .tab-content .tab-pane:first-child {
  padding: 0px 15px !important;
}

.customerDetail .customerDetailCard .tab-content .tab-pane:first-child th:first-child {
  padding-left: 0px !important;
}

.customerDetail .customerDetailCard .tab-content .tab-pane:first-child .table-product th:first-child {
  padding-left: 10px !important;
}

.flex-container {
  display: flex;
}

.flexInput {
  flex: 96%;
}

.flexAddButton {
  flex: 4%;
}

.flexMaxInput {
  flex: 98%;
}

.flexaddMinButton {
  flex: 2%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.modalBody .card {
  padding: 0;
  border: 0;
}

.report .col-lg-1 {
  padding-left: 0 !important;
}

.rdw-editor-wrapper {
  border: 1px solid #ddd;
  padding: 15px;
  min-height: 500px;
}

.textAreaStyle225 {
  height: 225px !important;
}

.custom-file {
  margin-bottom: 10px;
}

.custom-file-input:lang(en)~.custom-file-label::after,
.custom-file-label::after {
  display: none;
}

.custom-file-label {
  height: 40px !important;
  line-height: 2 !important;
  border: 1px solid #ddd;
  border-radius: 0;
  background-color: #fafafa;
  margin-top: 7px;
}

.custom-file-input:focus~.custom-file-label {
  box-shadow: none;
  border-color: none;
}


.printButton {
  width: 85px !important;
  background-color: rgb(0, 129, 0) !important;
}

.printButton:hover {
  background-color: rgb(0, 99, 0) !important;
}

.sendEmailButton {
  width: 120px !important;
  background-color: rgb(29, 75, 140) !important;
}

.sendEmailButton:hover {
  background-color: rgb(13, 49, 99) !important;
}

.fileRemoveButton {
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #aaa;
  position: absolute;
  right: 0;
  top: 31px;
  z-index: 2;
  padding: 3px 10px;
  border: 1px solid #ddd;
  border-top: none;
  border-bottom: none;
  border-right: none;
  background-color: unset;
  transition: 0.3s;
}

.fileRemoveButton:hover {
  cursor: pointer;
  color: #333;
}

.checkboxCol {
  margin-top: 30px;
  padding-right: unset !important;
}

.checkboxCol .custom-control-label {
  margin-left: 0 !important;
}

.serviceList .badge {
  min-width: 100% !important;
}

.serviceList #column-2 {
  margin-left: -50px !important;
}

.statusTopButton {
  padding: 6px 10px;
  text-align: center;
  margin-bottom: 2px;
  color: #fff;
  border-radius: 3px;
  opacity: 0.9;
  letter-spacing: 0.5px;
  font-size: 12px;
}

.statusTopButton:hover {
  color: #fff;
}

.statusTopButton:active {
  color: #fff;
}

.serviceOrangeButton:hover {
  background-color: #ff471a !important;
}

.serviceGreenButton:hover {
  background-color: #004d00 !important;
}

.modal.fade .modal-dialog {
  max-width: 93%;
}

.rc-time-picker {
  width: 100%;
}

.rc-time-picker-input {
  font-size: 13px;
  height: 40px;
  border: 1px solid #ddd;
  border-radius: 0px;
  box-shadow: none;
}

.rc-time-picker:hover {
  border-color: hsl(0, 0%, 70%);
}

.rc-time-picker-clear {
  top: 10px;
}

.custom-checkbox {
  padding-left: 30px !important;
  padding-top: 10px !important;
  z-index: 0 !important;
}

.customerTd {
  width: 200px;
  font-weight: 600;
  height: 35px;
}

.typeTd {
  font-weight: 600;
}

.table th {
  font-weight: 500 !important;
}

.itemLabel {
  vertical-align: inherit !important;
}

@media (max-width: 1200px) {
  #list .table {
    min-width: 900px !important;
  }

  .modal {
    max-width: 100% !important;
  }
}

@media (max-width: 1024px) {
  .table {
    margin: 0px;
    /* display: inline-block !important; */
  }
}

@media (max-width: 991px) {
  .content {
    margin-left: 0 !important;
    top: 0 !important;
  }

  .content h1 {
    font-size: 20px !important;
    margin-top: 60px;
  }

  .dynamicRowDeleteButton {
    margin-top: unset !important;
    margin-bottom: 12px;
  }

  .mobileBorder {
    padding: 10px;
    box-shadow: rgb(153 153 153) 0px 0px 5px;
    margin-bottom: 20px !important;
  }

  .labelCheck {
    padding-left: 20px;
    margin-bottom: 10px;
  }

  .noteList .buttonGroupCol {
    margin-top: 20px;
  }

  .barcodeButton,
  .barcodeButton:active,
  .barcodeButton:focus {
    margin: 5px auto 20px auto;
    width: 150px;
    float: none;
  }

  .modalSelectCol {
    padding: 0;
  }

  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    padding: 0 !important;
  }

  .btnReport {
    width: 75px !important;
    /* margin-top: unset !important; */
    margin-bottom: 60px;
  }

  .fileTopLabel {
    margin-left: unset !important;
  }

  .checkboxCol {
    margin-top: 10px !important;
  }
}

@media (max-width: 768px) {

  .collectionH2 {
    width: 100%;
  }

  .btnReport {
    margin-bottom: 20px !important;
  }

  #list .table {
    min-width: 300px !important;
  }

  #list .table td,
  #list .table th {
    display: flex !important;
    flex-direction: row !important;
    padding: 2px;
    font-size: 13px !important;
    padding-left: 2px !important;
    width: 100% !important;
  }


  .table td,
  .table th {
    display: flex !important;
    flex-direction: row !important;
    padding: 2px;
    font-size: 13px !important;
    padding-left: 2px !important;
    width: 100% !important;
  }

  .print .table td,
  .print .table th {
    display: inline !important;
    flex-direction: column !important;
  }

  #list .table th {
    font-weight: bold !important;
  }

  .table th {
    font-weight: bold !important;
  }

  .table tr:first-child td {
    border-top: 1px solid #ddd;
  }

  /* .table tr th, .table tr td{
  display: flex !important;
  flex-direction: row !important;
  width: auto;
}

.table th{
  font-weight: 600 !important;
} */

  .badge {
    margin-top: 10px;
  }

  .data-table .rdt_TableRow {
    display: flow-root;
  }

  .data-table .rdt_TableCell {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }

  .statusTopButton {
    width: 100%;
  }

  .content .btn-group .btn {
    margin-top: 10px;
    width: 100%;
    margin-left: 0px;
  }

  .content .contentTop .btn-group .btn {
    width: 100%;
    padding: 0px;
    margin-left: 1px;
  }

  .content .btn-group {
    width: 100%;
    display: block;
  }

  .modal .content .btn-group .btn {
    width: 100%;
  }

  .badge-danger {
    min-width: 100%;
  }

  .badge-success {
    min-width: 100%;
  }

  .ListDownButton {
    width: 100%;
    margin-right: 0px !important;
  }

  .content .card {
    padding: 8px;
  }

  .col {
    padding-right: 10px;
    padding-left: 10px;
  }

  .productFilterButton .btn-group .btn {
    width: 100% !important;
  }

  .filterRow {
    padding: 7px !important;
    margin-bottom: 8px !important;
  }

  .filterRow .listButton {
    width: 121%;
  }

  .filterRow .form-group {
    margin-bottom: 10px !important;
  }

  .colon {
    display: none !important;
  }
}

@media (max-width: 767px) {

  .customerDetail .nav,
  .supplierDetail .nav {
    display: block;
  }

  .login-page .card {
    top: 45% !important;
  }

  .contentTop .col-md-4 .btn-group {
    margin-top: 5px !important;
  }
}

@media (max-width: 576px) {
  .login-page {
    height: 110vh !important;
  }

  .login-page .card {
    width: unset !important;
    left: unset !important;
    margin-left: unset !important;
    margin-top: -271px !important;
    padding: 0 10px;
  }

  .react-confirm-alert-body {
    width: unset !important;
  }

  .css-1wa3eu0-placeholder {
    font-size: 12px !important;
  }

  .noteList .titleDesCol {
    padding: 0 !important;
  }

  .noteList .buttonGroupCol button {
    display: block;
    text-align: center;
    margin: 10px auto !important;
  }

  .modal-xl {
    margin: 0 auto !important;
  }

  .filterRow .listButton {
    margin-top: 5px !important;
  }
}


@media (max-width: 384px) {
  .login-page .card-body {
    position: relative;
  }

  .login-page .recaptcha {
    position: absolute;
    top: 195px;
    left: 0;
    right: 0;
  }

  .login-page .forgotInfoText {
    margin-top: 105px !important;
  }

  #forgotInformation .recaptcha {
    top: 108px !important;
  }

  #forgotInformation button {
    margin-top: 110px !important;
  }

  #refreshPassword button {
    margin-top: 118px !important;
  }
}

@media (max-width: 360px) {
  /* .content .btn-group .btn{
        width: unset !important;
    } */
}

@media (max-width: 321px) {
  .login-page .card {
    padding: 0 9px !important;
  }
}

.react-confirm-alert-body {
  width: 500px;
}

.filterRow {
  padding: 15px;
  margin-bottom: 15px !important;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  border-radius: 3px;
}

.filterRow .form-group {
  margin-bottom: 0px;
}

.filterRow .listButton {
  width: 100%;
  margin-top: 27px;
  height: 37px;
}

.filterRow label {
  font-weight: 600 !important;
}

.image-list {
  display: flex;
}

.image-list .image-list-item {
  float: left;
  margin-right: 10px;
}

.input-group .form-group {
  width: 85%;
}

.input-group .form-group label {
  position: relative;
  left: -40px;
}

.input-group .form-group .custom-control-label {
  left: 0px;
}

.input-group .input-group-text {
  height: 40px;
  margin-top: 27px;
  padding-right: 3px;
  border-radius: 0px;
  border-width: 2px;
  border-right: 0px none;
}

.input-group .input-group-text .custom-checkbox {
  padding-left: 22px !important;
  padding-top: 22px !important;
}

.work-accident {
  background-color: #1d4b8c;
  padding: 10px;
}

.work-accident p {
  color: #ffffff;
  margin: 0px;
  text-align: center;
  font-weight: 500;
}

.cart-count {
  background-color: #1c7530;
  padding: 10px;
}

.cart-count a,
.cart-count a:active,
.cart-count a:focus,
.cart-count a:hover {
  color: #ffffff;
  margin: 0px;
  text-align: center;
  font-weight: 500;
  display: block;
}

.dropdown-menu {
  border-radius: 3px;
}

.remind-count {
  background-color: #e30e1e;
  padding: 10px;
}

.remind-count a,
.remind-count a:active,
.remind-count a:focus,
.remind-count a:hover {
  color: #ffffff;
  margin: 0px;
  text-align: center;
  font-weight: 500;
  display: block;
}

.order-count {
  background-color: #2a928e;
  padding: 10px;
}

.order-count a,
.order-count a:active,
.order-count a:focus,
.order-count a:hover {
  color: #ffffff;
  margin: 0px;
  text-align: center;
  font-weight: 500;
  display: block;
}

.order-location-count {
  background-color: #004c45;
  padding: 10px;
}

.order-location-count a,
.order-location-count a:active,
.order-location-count a:focus,
.order-location-count a:hover {
  color: #ffffff;
  margin: 0px;
  text-align: center;
  font-weight: 500;
  display: block;
}

.chartRow canvas {
  margin-top: 15px;
  padding: 15px;
}

.dataTableInput {
  padding: 0px 10px;
  height: 31px;
  margin-top: -4px;
  text-align: center;
}

.dogum-gunu-text {
  text-align: center;
  font-weight: bold;
  margin: 0px;
  padding: 15px;
}

.procedure {
  padding-top: 20px;
}

.procedure .pdfIcon {
  width: 100%;
  border: 1px solid #ddd;
  margin-bottom: 5px;
}

.procedure h6 {
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  min-height: 80px;
  font-size: 15px;
  line-height: 19px;
}

.table-image {
  width: 50px;
  height: 50px;
}

.table-input .form-group {
  margin: 0px;
}

.table-input label {
  display: none;
}

.react-datepicker-popper {
  z-index: 10000;
}

.countdown-container {
  text-align: center;
  padding-top: 55px;
}

.countdown-container p {
  font-size: 25px;
  margin: 0px;
}

.countdown-container .count {
  font-weight: bold;
}

.planli-bakim-calismasi {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  text-align: center;
}

.modal-body {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.modal-footer {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}